import React from "react";
import "./App.css";
import { useState, useEffect } from "react";

function App() {
  const [ipAddress, setIpAddress] = useState("");
  const [geoInfo, setGeoInfo] = useState(null);

  useEffect(() => {
    // Trigger to get IP Address
    getVisitorIP();
  }, []);

  useEffect(() => {
    // Fetch location info after IP is set
    if (ipAddress) {
      fetchIPInfo();
    }
  }, [ipAddress]);

  // Get IP Address
  const getVisitorIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org");
      const data = await response.text();

      // Store IP in state
      setIpAddress(data);
    } catch (error) {
      console.error("Failed to fetch IP:", error);
    }
  };

  // Use IP from State to get location info
  const fetchIPInfo = async () => {
    try {
      const response = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=c9e89f73f2f7423c91e6973df57fb4d8&ip=${ipAddress}`);
      const data = await response.json();

      // Store location info in State
      setGeoInfo(data);
    } catch (error) {
      console.error("Failed to fetch location info:", error);
    }
  };

  return (
    <>
      <div className="app-container">
        <div className="content">
          <img className="logo" src="/assets/aimanimg.png" alt="Aiman's Logo" />
          <h1>Hi, I'm Aiman!</h1>
          <p className="description">- Makan la sikit, nanti sakit. -</p>
          <a
            href="https://x.com/muhdaimanrs"
            className="x-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="x-logo" src="/assets/X_logo.svg" alt="X logo" />
          </a>
        </div>
      </div>
      <div className="clientInfo">
        <p>Your IP Address: {ipAddress || "Fetching..."}</p>
        {geoInfo ? (
          <p>
            Your Location: {geoInfo.city}, {geoInfo.state_prov}, {geoInfo.country_name} ({geoInfo.latitude},{" "}
            {geoInfo.longitude})
          </p>
        ) : (
          <p>Fetching location...</p>
        )}
      </div>
      <footer className="footer">
        Made with{" "}
        <span role="img" aria-label="love">
          ❤️
        </span>{" "}
        from Jitra, Kedah.
      </footer>
    </>
  );
}

export default App;
